
const addFixedSpaceBeforePrepositions = () => {

    const prepositions = ["a", "s", "v", "k", "o", "u", "i", "z", "po", "od", "do", "nad", "pod", "před", "za"];
    const fixedSpace = "\u00A0";

    function addFixedSpaces(node) {
        if (node.nodeType === Node.TEXT_NODE) {
            let text = node.nodeValue;
            prepositions.forEach(preposition => {
                const regex = new RegExp(`\\b${preposition} `, "g");
                text = text.replace(regex, `${preposition}${fixedSpace}`);
            });
            node.nodeValue = text;
        } else if (node.nodeType === Node.ELEMENT_NODE && node.nodeName !== "SCRIPT" && node.nodeName !== "STYLE") {
            node.childNodes.forEach(addFixedSpaces);
        }
    }

    document.body.childNodes.forEach(addFixedSpaces);
}

export { addFixedSpaceBeforePrepositions }