
import { initUtils } from './utils';
import { initSliders } from './slider';
import { addFixedSpaceBeforePrepositions } from './cs-prepositions';
import { initDropdowns } from './dropdowns';
import { initMobileMenu } from './menu';


// Init all functions

function initAllScripts() {
    initUtils();
    initSliders();
    addFixedSpaceBeforePrepositions();
    initDropdowns();
    initMobileMenu();
}

if (document.readyState !== "loading") {
    initAllScripts();
} else {
    document.addEventListener("DOMContentLoaded", function () {
        initAllScripts();
    });
}