
const getScreenHeight = () => {
    return window.innerHeight;
};

const getScreenWidth = () => {
    return window.innerWidth;
};



 // Init

const initUtils = () => {
    getScreenHeight();
    getScreenWidth();
};

export {
    initUtils,
};
