
const initDropdowns = () => {

    let blocks = document.querySelectorAll(".js-dropdown-group");
    if (!blocks) return;

    const setupDropdownGroup = (block) => {

        const dropdowns = block.querySelectorAll(".js-dropdown");

        const scrollToTop = (element) => {
            if (element) {
                let elementPosition = element.getBoundingClientRect().top;

                window.scrollTo({
                    top: elementPosition + window.scrollY - 20,
                    behavior: "smooth"
                });
            };
        };

        const toggleDropdown = (event) => {
            const dropdown = event.target.closest(".js-dropdown");
            const content = dropdown.querySelector(".js-content");
            let keyframes;

            if (dropdown.classList.contains("in-transition")) {
                return;
            };

            //close active dropdown
            dropdowns.forEach((otherDropdown) => {
                if (otherDropdown !== dropdown) {
                    if (otherDropdown.classList.contains("is-open")) {
                        const otherContent = otherDropdown.querySelector(".js-content");
                        otherDropdown.classList.add("in-transition");
                        otherDropdown.classList.toggle("is-open");
                        keyframes = [{ height: `${otherContent.scrollHeight}px` }, { height: 0 }];
                        let animation = otherContent.animate(keyframes, { duration: 300 });
                        animation.onfinish = () => {
                            otherContent.classList.toggle("is-open");
                            otherDropdown.classList.remove("in-transition");
                        };
                    };
                };
            });


            dropdown.classList.add("in-transition");
            dropdown.classList.toggle("is-open");

            if (content.classList.contains("is-open")) {
                keyframes = [{ height: `${content.scrollHeight}px` }, { height: 0 }];
            } else {
                keyframes = [{ height: 0 }, { height: `${content.scrollHeight}px` }];
            };

            let animation = content.animate(keyframes, { duration: 300 });

            animation.onfinish = () => {
                content.classList.toggle("is-open");
                dropdown.classList.remove("in-transition");

                if (window.innerWidth < 1024) {
                    scrollToTop(dropdown);
                };

                if (dropdown.dataset.selfclose == 1) {
                    const selfClose = (e) => {
                        if (e.target.closest(".js-dropdown") === dropdown) return;
                        content.classList.remove("is-open");
                        dropdown.classList.remove("is-open");
                        document.removeEventListener("click", selfClose);
                    }
                    document.addEventListener("click", selfClose);
                };
            };
        };

        for (let dropdown of dropdowns) {
            let togglers = dropdown.querySelectorAll(".js-dropdown-toggler");
            togglers.forEach(element => {
                element.addEventListener("click", (event) => {
                    toggleDropdown(event);
                });
            });
        };
    };

    blocks.forEach(block => {
        setupDropdownGroup(block)
    });

};

export { initDropdowns }
