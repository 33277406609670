import Glide from '@glidejs/glide';


const initSliders = () => {

    const setupBlock = (block) => {

        const slider = block.querySelector(".glide");
        if (!slider) return;

        const pictures = block.querySelectorAll(".js-picture");
        const buttons  = block.querySelectorAll(".js-button");

        const updatePictures = (desiredState) => {
            if (!pictures) return;
            if (pictures.length < 1) return;
            pictures.forEach((element, index) => {
                if (index === desiredState) {
                    element.animate({ opacity: 1 }, { duration: 500 }).onfinish = () => {
                        element.classList.remove("opacity-0");
                    };
                } else {
                    element.animate({ opacity: 0 }, { duration: 500 }).onfinish = () => {
                        element.classList.add("opacity-0");
                    };
                }
            });
        }

        const updateButtons = (desiredState) => {
            if (!buttons) return;
            buttons.forEach((element, index) => {
                if (index === desiredState) {
                    element.classList.add("is-current");
                } else {
                    element.classList.remove("is-current");
                }
            });
        }

        let options = {
            type: 'carousel',
            autoplay: 4000,
            hoverpause: false,
            touchRatio: 0.2,
            touchAngle: 30,
            swipeTreshold: 40,
            dragTreshold: 60,
            animationDuration: 1000,
            perView: 1,
            peek: 0,
            gap: 30,
            breakpoints: {
               //
            }
        }

        const glide = new Glide(slider, options);

        /* setup control arrows and buttons */

        /*
        const arrowLeft  = block.querySelector(".js-left");
        const arrowRight = block.querySelector(".js-right");

        if (arrowLeft) {
            arrowLeft.addEventListener("click", () => {
                glide.go(`<`);
            })
        }

        if (arrowRight) {
            arrowRight.addEventListener("click", () => {
                glide.go(`>`);
            })
        }
        */

        if (buttons) {
            buttons.forEach((element, index) => {
                element.addEventListener('click', () => {
                    glide.go(`=${index}`);
                })
            });
        }


        glide.on('run', (event) => {

            updatePictures(glide.index);
            updateButtons(glide.index);
        })


        /* change slider cursor */

        slider.addEventListener('mousedown', () => { slider.style.cursor = 'grabbing' })
        slider.addEventListener('mouseup', () => { slider.style.cursor = 'grab' })
        slider.addEventListener('mouseenter', () => { slider.style.cursor = 'grab' })

        /* mount */

        glide.mount();
    }

    let blocks = document.querySelectorAll(".js-slider");

    if (!blocks) return;

    blocks.forEach(block => {
        setupBlock(block);
    });

}

export { initSliders }