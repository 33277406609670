
const initMobileMenu = () => {

    const menuButton = document.querySelector('.js-nav-button');
    const menuIcon   = document.querySelector('.js-hamburger-icon');
    const mobileMenu = document.querySelector('.js-mobile-menu');
    const menuItems  = document.querySelectorAll('.js-menu-item');

    menuButton.addEventListener('click', () => {
        mobileMenu.classList.toggle('is-open');
        menuIcon.classList.toggle('open');
    })

    window.addEventListener('click', (e) => {
        if(!mobileMenu.contains(e.target) && !menuButton.contains(e.target)) {
            mobileMenu.classList.remove('is-open');
            menuIcon.classList.remove('open');
        }
    })

    menuItems.forEach(item => {
        item.addEventListener('click', () => {
            mobileMenu.classList.remove('is-open');
            menuIcon.classList.remove('open');
        })
    })
}

export {initMobileMenu}